import {Row, Col, Card, Button} from "react-bootstrap";
import ElevatedCard from "../utils/ElevatedCard";
import ImageZoom from "../utils/ImageZoom";
import routeMap from "../../resources/routeMap.png"

function MainEventSubPage () {
  return(
    <Row className="mb-3 py-4 mx-0 justify-content-center">
        <Col className="mb-3 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>
            <Row>
                <h2 className="fs-2 fw-bold">The Manchester 24 Hour Run Against Homelessness 2024</h2>
            </Row>
            <Row className="fw-bold justify-content-center">
                <Col sm={8} md={6} className="mt-3 justify-content-center">
                    <ElevatedCard colour="#FFA500" elevation={4}>
                        <Card.Body><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"Wednesday 13th - Thursday 14th November 2024"}</Card.Body>
                    </ElevatedCard>
                </Col>
                <Col sm={8} md={6} className="mt-2">
                    <ElevatedCard colour="#FFCC00" elevation={4}>
                        <Card.Body><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{"Location: Freight Island, 11 Baring St, Manchester M1 2PY"}</Card.Body>
                    </ElevatedCard>
                </Col>
            </Row>
            <Row>
                <p className="fs-5 text-center mt-4">{"The Manchester 24 Hour Run Against Homelessness is a mass participation group relay run that is held over 24 hours. Runners and walkers set off together in waves to complete 5km laps in the city centre of Manchester, all to raise money for the Greater Manchester Mayor's Charity scheme 'A Bed Every Night'."}</p>
                <p className="fs-5 text-center">Your donations will go even further through the generosity of our partner, <a className="fw-bold" href="https://togethermoney.com/">Together</a>, matching public donations up to £20,000.</p>
            </Row>
            <Row className="justify-content-center fs-5">
                <Row><div className="divider div-transparent div-dot mb-3 mt-3"></div></Row>
                <h2 className="mt-3">How do I take part?</h2>
                <p >It's simple - just turn up to our host venue at any time from midday on Wednesday 13th November, until midday on Thursday 14th November, and join the next relay wave departing on a 5km lap.</p>
                <Col className="mb-4"><Button href="https://docs.google.com/forms/d/e/1FAIpQLSfUSzVEVr-E77SmShbppHMDg75FE4P8GP8IHAEDP-8Xu2Gw_w/viewform">Sign up to email reminders</Button></Col>
                <Row><div className="divider div-transparent div-dot mb-3 mt-3"></div></Row>
                <h2 className="mt-3">What is the format?</h2>
                <p >Each relay group is led by one of our run leaders, who will maintain a pace that keeps everyone together for a social run. Participants can take part in as many, or as few laps as they would like. We aim to have a relay group set off once every 30 minutes - however, this will vary, due to groups running at different paces.</p>
                <Row><div className="divider div-transparent div-dot mb-3 mt-3"></div></Row>
                <h2 className="mt-3">Where is it held?</h2>
                <p>The event is based at our host venue in Manchester City Centre, with many train stations, tram stops, and bus stops close by. The route passes through Deansgate, along Princess Street and Upper Brook Street, through Brunswick Park, and finally along Oxford Road back to Watson Street. <b>An updated route for our new host venue is coming soon.</b></p>
                <ImageZoom src={routeMap} alt={"map"} fullHeight={2572} fullWidth={3000}/>
                <Row className="mt-3"><div className="divider div-transparent div-dot mb-3 mt-4"></div></Row>
                <h2 className="mt-3">Can I bring a group?</h2>
                <p>Absolutely - we want as many people involved in the event as possible, so that we can have the biggest possible impact. We strongly encourage you to advertise the event amongst friends, colleagues, running clubs, or anyone else. If you are bringing a large group of around 10 or more people, then please help our organisers by filling in our <a href="https://docs.google.com/forms/d/e/1FAIpQLSdsJ9nZ5-oyW4L6q4WB7KfDQI8HBDZ930x2ROanFubUccVxZw/viewform">group sign up form</a> so that we know to expect you.</p>
                <Col><Button href="https://docs.google.com/forms/d/e/1FAIpQLSdsJ9nZ5-oyW4L6q4WB7KfDQI8HBDZ930x2ROanFubUccVxZw/viewform">Group Sign Up</Button></Col>
                {/*<Row><div className="divider div-transparent div-dot mb-3 mt-3"></div></Row>*/}
                {/*<h2 className="mt-3">Can I start my own fundraising page?</h2>*/}
                {/*<p >Yes! If you want to track your own fundraising separately from our main campaign, you can set up your own page as part of our JustGiving group. Your total will be added to our main total, but you will have a separate page to track how many people donate to your own personal campaign.</p>*/}
                {/*<Col><Button href="http://www.justgiving.com/team/MCR-24-Hour-Run-2023?invite=true">Join our fundraising team</Button></Col>*/}
            </Row>            
        </Col>
    </Row>
  );
}

export default MainEventSubPage;